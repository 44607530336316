var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-0",staticStyle:{"overflow-y":"auto","height":"100%","width":"100%"},attrs:{"fluid":""}},[_c('v-data-iterator',{staticClass:"d-flex flex-column",staticStyle:{"height":"100%"},attrs:{"items":_vm.superProfilesList,"server-items-length":_vm.superProfiles.length,"loading":_vm.isLoading,"hide-default-footer":""},on:{"update:page":_vm.onPageNumberUpdated},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"pa-3 pb-4 d-flex align-center",staticStyle:{"border-bottom":"1px solid"},style:({
          borderColor: _vm.$vuetify.theme.themes.light.border,
        })},[_c('input-text',{attrs:{"max-width":"440px","left-icon":"mdi-magnify","placeholder":_vm.$t('deconve.search')},model:{value:(_vm.filterOptions.search),callback:function ($$v) {_vm.$set(_vm.filterOptions, "search", $$v)},expression:"filterOptions.search"}}),_c('v-menu',{attrs:{"close-on-content-click":false,"offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",class:_vm.hasFilterChanged? 'primaryHighlight' : 'white',attrs:{"icon":"","color":_vm.hasFilterChanged? 'primary' : 'neutral',"small":_vm.$vuetify.breakpoint.mobile}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" "+_vm._s(_vm.hasFilterChanged? _vm.icons.selectedFilterIcon : _vm.icons.outlineFilterIcon)+" ")])],1)]}}])},[_c('super-profile-filter',{attrs:{"has-changed":_vm.hasFilterChanged},on:{"change":_vm.onFilterChanged,"clear":_vm.clearFilters},model:{value:(_vm.filterOptions),callback:function ($$v) {_vm.filterOptions=$$v},expression:"filterOptions"}})],1)],1)]},proxy:true},{key:"loading",fn:function(){return [_c('span',{staticClass:"ml-1 pa-2"},[_vm._v(" "+_vm._s(_vm.$t('deconve.loading'))+" ")])]},proxy:true},{key:"no-data",fn:function(){return [_c('span',{staticClass:"ml-1 pa-2 neutralPrimary--text text-subtitle-2 text-sm-subtitle-1 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t('deconve.noSuperProfile'))+" ")])]},proxy:true},{key:"default",fn:function(props){return [_c('div',{staticStyle:{"flex":"1","overflow-y":"auto"},style:({
          backgroundColor: _vm.$vuetify.theme.themes.light.background,
        })},[(_vm.isLoading)?_c('v-progress-linear',{attrs:{"indeterminate":true}}):_vm._e(),_c('div',{staticClass:"pa-2"},[_c('div',{staticClass:"d-flex flex-wrap"},_vm._l((props.items),function(item){return _c('v-col',{key:item.id,staticClass:"pa-1",attrs:{"cols":"12","sm":"6","md":"4","lg":"3","data-cy":item.id}},[_c('super-profile-preview',{attrs:{"id":item.id}})],1)}),1)])],1)]}},{key:"footer",fn:function(){return [_c('footer-data-iterator',{attrs:{"items-per-page-array":_vm.itemsPerPageArray,"items-per-page":_vm.filterOptions.itemsPerPage,"page":_vm.filterOptions.page,"total-data":_vm.numberOfSuperProfiles},on:{"changeItensPerPage":_vm.updateItemsPerPage,"changePage":_vm.onPageNumberUpdated}})]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }